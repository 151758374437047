

















































































import {
SfHeading,
SfLoader,
SfRadio,
} from '@storefront-ui/vue';

import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import useCart from '~/modules/checkout/composables/useCart';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCartStore } from '~/modules/checkout/stores/cart';

import BKButton from '~/components/BK/BKButton.vue';
import type { AvailableShippingMethod, SelectedShippingMethod } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'VsfShippingProvider',
  components: {
    SfHeading,
    SfRadio,
    SfLoader,
    BKButton,
  },
  props: {
    shippingMethods: {
      type: Array as PropType<AvailableShippingMethod[]>,
      default: () => [],
    },
    cartHasBike: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { cart } = useCart();
    const state = ref<SelectedShippingMethod | null>(null);
    const cartStore = useCartStore();

    const {
      save: saveShippingProvider,
      error: errorShippingProvider,
      loading: isLoading,
    } = useShippingProvider();

    const selectedShippingMethod = computed(() => state.value);
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const isShippingMethodStepCompleted = computed(
      () => state.value?.method_code && !isLoading.value,
    );

    const selectShippingMethod = async (method: AvailableShippingMethod) => {
      const shippingData = {
        carrier_code: method.carrier_code,
        method_code: method.method_code,
      };

      state.value = await saveShippingProvider({
        shippingMethod: shippingData,
      });

      cartStore.$patch((cartState) => {
        // eslint-disable-next-line no-param-reassign
        cartState.canShowShippingMethod = true;
      });
    };

    return {
      errorShippingProvider,
      getShippingMethodPrice,
      isLoading,
      isShippingMethodStepCompleted,
      selectedShippingMethod,
      selectShippingMethod,
      state,
      totals,
    };
  },
});
